export const palette = {
  // website
  primary: "#3498db",
  secondary: "#2980ba",
  black: "#191b1d",
  white: "#fff",
  // social media
  fb: "#3b5998",
  github: "#333",
  linkedIn: "#007bb5"
};

export const device = {
  tablet: "767px",
  desktop: "991px",
  largeDesktop: "1199px"
};

export const typography = {
  xl: "48px",
  lg: "32px",
  md: "18px",
  sm: "14px",
  xs: "11px",
  lhXl: "54px",
  lhLg: "38px",
  lhMd: "24px",
  lhSm: "20px",
  lhXs: "17px"
};
