import styled from 'styled-components';

export const Styled = styled.main`
  display: flex;
  height: 100vh;
  flex-direction: column;

  header {
    flex-shrink: 1;
  }

  section {
    flex-grow: 1;
  }
`;
